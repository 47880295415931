/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SortColumns {
    LOCATION_GROUP_NAME = 'name',
    LOCATION_GROUP_ID = 'id',
    START_DATE = 'created',
    STATUS = 'status'
}
