import React, { useState, useEffect } from 'react';
import { ReactComponent as GtLogo } from '../../images/groundtruth-logo.svg';
import { ReactComponent as GtLogoText } from '../../images/groundtruth-logo-text-white.svg';
import config from '../../config';
import './index.css';

interface Icon {
    key: string;
    url: string;
    text: string;
}

interface AvailableAppsProps {
    authApps: string
}

function AvailableApps(props: AvailableAppsProps) {
    const { authApps } = props;

    const [iconsToRender, setIconsToRender] = useState<Icon[]>([]);

    const appIcons = {
        LIP: {
            url: config.discoveryUrl,
            text: 'Discovery'
        },
        LM: {
            url: config.locationManagerUrl,
            text: 'Location Manager'
        },
        CAB: {
            url: config.audienceManagerUrl,
            text: 'Audience Manager'
        },
        MF: {
            url: config.adsManagerUrl,
            text: 'Ads Manager'
        }
    };

    const renderIcons = () => {
        const icons: Icon[] = [];
        authApps?.split(',').forEach((appKey: string) => {
            if (appIcons[appKey as keyof typeof appIcons]) {
                icons.push({
                    key: appKey,
                    url: appIcons[appKey as keyof typeof appIcons].url,
                    text: appIcons[appKey as keyof typeof appIcons].text
                });
            }
        });
        setIconsToRender(icons);
    };

    useEffect(() => {
        renderIcons();
    }, []);

    return (
        <div className='available-apps'>
            <h6 className='available-apps__header'>Available Apps</h6>
            <div className='available-apps-container'>
                {iconsToRender.map((icon) => (
                    <div className='available-apps__app-btn' key={icon.key}>
                        <button
                            type='button'
                            onClick={() => window.open(icon.url, '_blank')}
                        >
                            <div className='app-logo-container'>
                                <GtLogo className='gt-logo' />
                            </div>
                            <div className='app-logo-text-container'>
                                <GtLogoText />
                            </div>
                            <div className='app-name'>{icon.text}</div>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AvailableApps;
